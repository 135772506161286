<template>
	<b-container class="vh-100">
		<b-row>
			<b-col class="text-left mt-3">
				<b-link @click="cancelSelection()">
					<img alt="Tulikas logo" src="../../assets/tulikas-logo.jpg">
				</b-link>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="mb-2 mt-5 text-right">
				<template v-if="$route.path === '/tulikas/fireaudit'">
					<div class="text-right mb-3">
						<h1 style="font-size: max(4vh, 30px);">
							Kas teie hoones on vaja teostada tuleohutusülevaatus?
						</h1>
						<h3 style="color: #fff100; font-size: max(3vh, 30px);" class="mt-3">
							Sisesta hoone aadress ja saad KOHESELT vastuse
						</h3>
						<div class="mt-3">
							<b style="color: lightgrey; font-size: max(2vh, 18px);">
								ning meiepoolse hinnapakkumise ülevaatuse teostamiseks
							</b>
						</div>
					</div>

					<template v-if="!selectedBuilding">
						<b-form-input v-model="filters.address"
									  type="text"
									  debounce="250"
									  @update="getBuildings()"
									  placeholder="Ehitise aadress või EHR kood"
						></b-form-input>
						<b-list-group>
							<b-list-group-item
								v-for="(building, bIdx) in buildings"
								:key="bIdx"
								button
								@click="selectBuilding(building)"
							>
								<b>{{ building.ehr_code }} ({{ building.building_name }})</b> | {{ building.building_address }} | <b>{{ building.closed_net_area }} m²</b>
							</b-list-group-item>
						</b-list-group>
					</template>

					<template v-else>
						<b-card text-variant="dark">
							<b-container class="mt-4 mb-4 text-left" fluid>
								<b-row class="my-1">
									<b-col sm="30">
										<label for="ehr-code">EHR kood</label>
									</b-col>
									<b-col sm="90">
										<b-form-input v-model="selectedBuilding.ehr_code" disabled></b-form-input>
									</b-col>
								</b-row>
								<b-row class="my-1">
									<b-col sm="30">
										<label for="ehr-code">Ehitise tüüp</label>
									</b-col>
									<b-col sm="90">
										<b-form-input v-model="selectedBuilding.building_type_est" disabled></b-form-input>
									</b-col>
								</b-row>
								<b-row class="my-1">
									<b-col sm="30">
										<label for="ehr-code">Aadress</label>
									</b-col>
									<b-col sm="90">
										<b-form-input v-model="selectedBuilding.building_address"></b-form-input>
									</b-col>
								</b-row>
								<b-row class="my-1">
									<b-col sm="30">
										<label for="ehr-code">Suletud netopind, m²</label>
									</b-col>
									<b-col sm="90">
										<b-form-input v-model="selectedBuilding.closed_net_area"
													  @update="updateAuditPrice(selectedBuilding)"
													  type="number"
										></b-form-input>
									</b-col>
								</b-row>
								<b-row class="my-1">
									<b-col sm="30">
										<label for="ehr-code">Ehitise nimi</label>
									</b-col>
									<b-col sm="90">
										<b-form-input v-model="selectedBuilding.building_name"></b-form-input>
									</b-col>
								</b-row>
								<b-row class="my-1">
									<b-col sm="30">
										<label for="ehr-code">Kasutuselevõtu kuupäev</label>
									</b-col>
									<b-col sm="90">
										<b-form-input v-model="selectedBuilding.first_use_date"></b-form-input>
									</b-col>
								</b-row>
							</b-container>

							<b-alert v-if="selectedBuilding && !mustPassAudit" class="text-center" variant="success" show>
								Meie andmetel ei ole antud hoone puhul tegemist sellise hoonega, millele laieneks tuleohutusülevaatuse teostamise kohustus.
								<br><br>
								Kui te aga kahtlete selles, siis kontrollige palun üle ehitise kohta käivad andmed Ehitisregistris ning vajadusel viige need tegelikkusega kooskõlla.
								<br><br>
								Ehitisregistri andmete korrastamiseks saate abi siit <b-link href="https://vanamaja.ee">vanamaja.ee</b-link>.
							</b-alert>

							<template v-if="selectedBuilding && mustPassAudit">
								<b-alert variant="warning" class="text-left" style="background-color: #ff791a; color: white;" show>
									<b-row class="m-3">
										<b-col cols="20">
											<img src="../../assets/fire-extinguisher-on-check-list.jpg" height="100" width="100">
										</b-col>
										<b-col>
											<h2>
												Meie andmetel tuleb selles hoones 2022.a jooksul teostada tuleohutusülevaatus
											</h2>
										</b-col>
									</b-row>
								</b-alert>

								<div class="text-center m-4">
									<b>
										Sisestades tellija andmed arvutame Teie hoone tuleohutusülevaatuse hinna ja sobivusel saate teenuse ühe klikiga tellida.
									</b>
								</div>

								<b-alert variant="light" class="text-left" show>
									<b-row class="my-1">
										<b-col sm="30">
											<label for="contact-name" class="mt-2">Tellija</label>
										</b-col>
										<b-col sm="90">
											<b-form-input v-model="filters.business"
														  type="text"
														  debounce="250"
														  @update="getBusinesses()"
														  :state="validateName"
														  placeholder="Nimi või registrikood"
											></b-form-input>
											<b-list-group>
												<b-list-group-item
													v-for="(business, bIdx) in businesses"
													:key="bIdx"
													button
													@click="selectBusiness(business)"
												>
													<b>{{ business.ariregistri_kood }}</b> {{ business.nimi }}
												</b-list-group-item>
											</b-list-group>
											<b-form-invalid-feedback :state="validateName">
												Sisesta tellija nimi või registrikood
											</b-form-invalid-feedback>
										</b-col>
									</b-row>
									<b-row class="my-1">
										<b-col sm="30">
											<label for="reg-code" class="mt-2">Tellija registrikood</label>
										</b-col>
										<b-col sm="90">
											<b-form-input v-model="contacts.regCode"
														  :state="validateRegCode"
														  id="reg-code"
											></b-form-input>
											<b-form-invalid-feedback :state="validateRegCode">
												Sisesta tellija registrikood
											</b-form-invalid-feedback>
										</b-col>
									</b-row>
									<b-row class="my-1">
										<b-col sm="30">
											<label for="email" class="mt-2">Tellija email</label>
										</b-col>
										<b-col sm="90">
											<b-form-input v-model="contacts.email"
														  :state="validateEmail"
														  id="email"
											></b-form-input>
											<b-form-invalid-feedback :state="validateEmail">
												Sisesta tellija email
											</b-form-invalid-feedback>
										</b-col>
									</b-row>
								</b-alert>

								<template v-if="contactsValid && priceWatched">
									<b-alert v-if="fireAuditCost" variant="success" class="mt-3 text-center" show>
										Tuleohutusülevaatuse maksumus teie hoonele:<br>
										<br>
										<h2>
											{{ fireAuditCost }} €
										</h2>
										<i>
											Tellimuse kinnitamise järgselt saadame teile <b>ettemaksuarve summale 250.- eurot +km</b>. Tellimus jõustub pärast ettemaksu laekumist.
											Seejärel saadame teile nimekirja ülevaatuse teostamiseks vajalikest dokumentidest ja lepime kokku hoone kohapealse ülevaatuse teostamise aja.
										</i>
										<hr>
										<i>
											<b>Vajadusel korrigeeri ülaltoodud ehitise andmeid enne tellimuse tegemist.</b>
										</i>
									</b-alert>
								</template>

							</template>

							<b-alert v-if="serverError" class="text-center" variant="danger" show>
								Päringuga on mingi probleem - palun teavita sellest info@tulikas.ee
								<br>
								<i>{{ serverError }}</i>
							</b-alert>

							<b-button @click="cancelSelection()"
									  variant="outline-dark"
									  class="mt-4 mb-4 float-left"
							>
								Tagasi
							</b-button>
							<div class="text-center">
								<b-button v-if="!priceWatched && fireAuditCost"
										  @click="watchPrice()"
										  variant="success"
										  class="mt-4 mb-4 ml-2"
										  :disabled="!contactsValid"
										  size="lg"
										  style="height: 10vh; width: 30vh;"
								>
									Vaata hinda
								</b-button>
								<b-button v-if="priceWatched"
										  @click="confirmOrder()"
										  variant="success"
										  class="mt-4 mb-4 ml-2"
										  size="lg"
										  style="height: 10vh; width: 30vh;"
								>
									<b-spinner small v-if="submitSpinner"></b-spinner>
									Kinnitan tellimuse
								</b-button>
							</div>
						</b-card>

					</template>
				</template>

				<template v-if="$route.path === '/tulikas/fireaudit/success'">

					<div class="text-center mt-4 mb-4">
						<b-alert variant="success" class="mt-2" show>
							Aitäh tellimuse eest!
							<hr>
							Võtame teiega ühendust.
						</b-alert>

						<b-button to="/tulikas/fireaudit"
								  variant="warning"
								  size="lg"
								  style="height: 6vh; width: 30vh; background-color: #ff791a; color: white"
						>
							Kontrolli järgmist hoonet
						</b-button>
					</div>

				</template>

			</b-col>
		</b-row>

		<b-row align-v="stretch">
			<b-col class="mt-5 mb-4">
				<div style="font-weight: bold; font-style: italic;">
					Muutunud <a style="color: lightgrey;" href="https://www.riigiteataja.ee/akt/13314859?leiaKehtiv" target="_blank">tuleohutuse seaduse</a> järgi on hiljemalt 1. jaanuariks 2023.a tuleohutusülevaatus vaja teha büroohoonetes pindalaga üle 750 m², tööstus- ja laohoonetes üle 1000 m² ning garaažides üle 1000 m².
				</div>
			</b-col>
		</b-row>

	</b-container>
</template>

<script>

import _ from 'lodash';
import Swal from 'sweetalert2';
import axios from 'axios';

export default {
	components: {
	},
	data () {
		return {
			filters: {
				address: '',
				business: '',
			},
			buildings: [],
			selectedBuilding: false,
			businesses: [],
			selectedBusiness: false,
			mustPassAudit: false,
			contacts: {
				name: '',
				regCode: '',
				email: '',
			},
			fireAuditCost: false,
			priceWatched: false,
			ordererCompleted: false,
			submitSpinner: false,
			serverError: false,
		}
	},
	created() {
		this.getBuildings();
	},
	computed: {
		validateEmail() {
			if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.contacts.email)) {
				return true;
			} else {
				return false;
			}
		},
		validateName() {
			if (this.contacts.name.length < 4) {
				return false;
			} else {
				return true;
			}
		},
		validateRegCode() {
			if (/^\d{8}$/.test(this.contacts.regCode)) {
				return true;
			} else {
				return false;
			}
		},
		contactsValid() {
			this.priceWatched = false;
			return this.validateName && this.validateRegCode && this.validateEmail;
		}
	},
	methods: {
		getBuildings() {
			if (this.filters.address !== '') {
				axios.post('/buildings', this.filters).then(({data}) => {
					this.buildings = data;
				});
			} else {
				this.buildings = [];
			}
		},
		selectBuilding(building) {
			this.selectedBuilding = building;
			this.selectedBuilding.building_type_est = this.buildingType(building.building_type);
			this.selectedBuilding.first_use_date = building.first_use_date > 0 ? building.first_use_date : '-';
			this.buildings = [];
			this.priceWatched = false;
			this.ordererCompleted = false;
			this.updateAuditPrice(building);
		},
		getBusinesses() {
			this.contacts.regCode = '';
			if (this.filters.business !== '') {
				axios.post('/businesses', this.filters).then(({data}) => {
					this.businesses = data;
				});
			} else {
				this.businesses = [];
			}
		},
		selectBusiness(business) {
			this.selectedBusiness = business;
			this.filters.business = business.nimi;
			this.contacts.name = business.nimi;
			this.contacts.regCode = business.ariregistri_kood;
			this.businesses = [];
		},
		cancelSelection() {
			this.selectedBuilding = false;
			this.filters.address = '';
		},
		buildingType(type) {
			if (type == 'OFFICE BUILDINGS') {
				return 'Büroohoone';
			} else if (type == 'INDUSTRIAL AND WAREHOUSES') {
				return 'Tööstus- / laohoone';
			} else if (type == 'GARAGES') {
				return 'Garaaž';
			}
			return '';
		},
		checkForAudit(type, netArea) {
			if (type === 'OFFICE BUILDINGS' && netArea > 750) {
				return true;
			}
			else if (type === 'INDUSTRIAL AND WAREHOUSES' && netArea > 1000) {
				return true;
			}
			else if (type === 'GARAGES' && netArea > 1000) {
				return true;
			}
			return false;
		},
		updateAuditPrice(building) {
			this.mustPassAudit = this.checkForAudit(building.building_type, building.closed_net_area);
			this.priceWatched = false;
			if (this.mustPassAudit) {
				this.fireAuditCost = this.fireAuditCostCalculate(
					building.closed_net_area,
					building.building_address,
				);
			} else {
				this.fireAuditCost = false;
			}
		},
		fireAuditCostCalculate(netArea, address) {
			let cost = 0;
			// add size cost
			if (netArea > 750 && netArea <= 1000) {
				cost += 470;
			}
			else if (netArea > 1000 && netArea <= 1500) {
				cost += 570;
			}
			else if (netArea > 1500 && netArea <= 2000) {
				cost += 650;
			}
			else if (netArea > 2000) {
				cost += 750;
			}
			// add location cost
			if (_.startsWith(address, 'Harju maakond') || _.startsWith(address, 'Tartu maakond')) {
				cost += 0;
			}
			else if (_.startsWith(address, 'Ida-Viru maakond') || _.startsWith(address, 'Pärnu maakond') || _.startsWith(address, 'Saare maakond') || _.startsWith(address, 'Hiiu maakond')) {
				cost += 150;
			} else {
				cost += 100;
			}
			return cost;
		},
		watchPrice() {
			this.priceWatched = true;
		},
		confirmOrder() {
			this.serverError = false;
			let data = {
				selectedBuilding: this.selectedBuilding,
				contacts: this.contacts,
				fireAuditCost: this.fireAuditCost
			};
			this.submitSpinner = true;
			axios.post('/fireaudit_order', data).then(({data}) => {
				this.ordererCompleted = true;
				this.selectedBuilding = false;
				this.submitSpinner = false;
				this.filters.address = '';
				this.$router.push({ path: '/tulikas/fireaudit/success' });
			}).catch((error) => {
				if (error) {
					this.serverError = error;
				}
			});
		},
	}
}
</script>

<style>
.vs--searchable .vs__dropdown-toggle {
	height: 38px;
}
</style>
